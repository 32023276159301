const headerFirtsLineDesktop = document.querySelector(".main-header > .container");
const header = document.querySelector(".main-header-bundle");
const hoverToMenu = document.querySelector(".area-hover-to-menu");

let scrollPrev = 0;

const hideToFirstLineHeaderDesktop = (scrollCurrentPositionY) => {
    const currentViewreport = window.innerWidth;

    if(scrollCurrentPositionY > 300 && currentViewreport >= 1200) {
        headerFirtsLineDesktop.classList.add("js-is-scroll");
    } else {
        headerFirtsLineDesktop.classList.remove("js-is-scroll");
    }

    // hoverToMenu.addEventListener("mouseover", () => {
    //     header.classList.remove("is-menu-hidden");
    // });

    // header.addEventListener("mouseover", () => {
    //     header.classList.remove("is-on-top");
    // });

    // header.addEventListener("mouseleave", () => {
    //     if (currentViewreport > 1200) {
    //         if (scrollCurrentPositionY < 50) {
    //             header.classList.add("is-on-top");
    //             header.classList.remove("is-left-top");
    //         } else {
    //             header.classList.add("is-left-top");
    //             header.classList.remove("is-on-top");
    //         }
    //     }
    // });

    // if (currentViewreport > 1200) {
    //     if (scrollCurrentPositionY > 500) {
    //         if (scrollCurrentPositionY >= scrollPrev) {
    //             header.classList.add("is-menu-hidden");
    //         } else {
    //             header.classList.remove("is-menu-hidden");
    //         }
    //     } else {
    //         header.classList.remove("is-menu-hidden");
    //     }
    //     scrollPrev = scrollCurrentPositionY;
    // }

    // if (scrollCurrentPositionY < 50) {
    //     header.classList.add("is-on-top");
    //     header.classList.remove("is-left-top");
    // } else {
    //     header.classList.add("is-left-top");
    //     header.classList.remove("is-on-top");
    // }
};

module.exports = window.addEventListener("scroll", () => {
    hideToFirstLineHeaderDesktop(scrollY);
});
