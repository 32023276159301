import Swiper from "swiper/dist/js/swiper.min.js";

export default () => {
    const blockMainBanner = new Swiper(".block-main-banner", {
        spaceBetween: 20,
        pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
        },
    });

    const unidadesSlider = new Swiper(".unidades-slider", {
        slidesPerView: 2,
        pagination: {
            el: ".pagination-unidades",
            type: "bullets",
            clickable: true,
        },

        breakpoints: {
            1500: {
                slidesPerView: 2,
                spaceBetween: 40,
            },

            1100: {
                slidesPerView: 1,
                spaceBetween: 40,
            },
        },
    });

    const servicosSlider = new Swiper(".servicos-slider", {
        autoplay: {
            delay: 3000,
        },
        slidesPerView: 3,
        pagination: {
            el: ".swiper-pagination-servicos",
            type: "bullets",
            clickable: true,
        },

        loop: true,

        breakpoints: {
            1200: {
                slidesPerView: 3,
            },
            1199: {
                slidesPerView: 1,
            },
        },
    });
};
